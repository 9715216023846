.welcome-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 7px 8px rgba(0, 0, 0, 0.2);
    padding: 32px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    min-height: 15rem;

  }
  
  .welcome-icon {
    font-size: 48px;
    color: #007bff;
    margin-bottom: 24px;
  }
  
  .welcome-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .welcome-text {
    font-size: 16px;
    margin-bottom: 32px;
  }
  
  .welcome-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .welcome-button:hover {
    background-color: #0062cc;
  }
  .camera-icon{
    margin-right: 0.5rem;
    margin-top: 0.1rem;
  }

  .option-card{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

  }

  .live-camera-card{
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 7px 8px rgba(0, 0, 0, 0.2); */
    /* padding: 32px; */
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    min-height: 15rem;
  }

  .selected-image{
    max-width: 400px;
    max-height: 10rem;
  }
  .selected-image-card{
    /* display: grid; */
    justify-content: center;
  }
  .close-detect-btn {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
  }

  .close-detect-btn .action-button {
    width: 16rem;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .options-buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
  }

  .option-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .option-button:hover {
    background-color: #0056b3;
  }

  .camera-icon {
    font-size: 1.2rem;
  }

  .back-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .back-button:hover {
    background-color: #5a6268;
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .image-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }

  .action-button {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 500;
  }

  .detect-button {
    background-color: #28a745;
    color: white;
  }

  .detect-button:hover {
    background-color: #218838;
  }

  .detect-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  .user-details {
    margin-top: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .user-details h3 {
    margin-bottom: 15px;
    color: #333;
    text-align: center;
  }

  .detail-row {
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }

  .detail-row b {
    color: #555;
    margin-right: 10px;
    min-width: 80px;
    display: inline-block;
  }

  .result-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }

  .user-details {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .user-details h3 {
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.2rem;
  }

  .detail-row {
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .detail-row:last-child {
    border-bottom: none;
  }

  .detail-row b {
    width: 80px;
    color: #666;
  }

  .detail-row span {
    flex: 1;
    color: #333;
  }