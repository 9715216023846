.form {
    width: 700px;
    background: radial-gradient(
        circle,
        transparent 20%,
        slategray 20%,
        slategray 80%,
        transparent 80%,
        transparent
      ),
      radial-gradient(
          circle,
          transparent 20%,
          slategray 20%,
          slategray 80%,
          transparent 80%,
          transparent
        )
        50px 50px,
      linear-gradient(#a8b1bb 8px, transparent 8px) 0 -4px,
      linear-gradient(90deg, #a8b1bb 8px, transparent 8px) -4px 0;
    background-color: slategray;
    background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
  }